import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlickComponent } from 'ngx-slick';
import { PhotoModalService } from '../photo-modal/photo.modal.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: [
    './carousel.component.css'
  ]
})

export class CarouselComponent implements AfterViewInit, OnInit {
  @ViewChild(SlickComponent, { static: false }) slickComponent;
  @Input() slidesToShow;

  nextSlideDelay: number = 5000;

  slideConfig;

  slides = [
    {
      thumbnailSrc: 'assets/carousel-image-thumbnails/1.jpg',
      fullSrc: 'assets/carousel-images/1.jpg',
      alt: 'Wicking bed'
    },
    {
      thumbnailSrc: 'assets/carousel-image-thumbnails/2.jpg',
      fullSrc: 'assets/carousel-images/2.jpg',
      alt: 'Backyard hedge and mowed lawn'
    },
    {
      thumbnailSrc: 'assets/carousel-image-thumbnails/3.jpg',
      fullSrc: 'assets/carousel-images/3.jpg',
      alt: 'Hedges, shrubs and mowed lawn'
    },
    {
      thumbnailSrc: 'assets/carousel-image-thumbnails/4.jpg',
      fullSrc: 'assets/carousel-images/4.jpg',
      alt: 'Landscaping, mowed loan and trimmed plants'
    },
    {
      thumbnailSrc: 'assets/carousel-image-thumbnails/5.jpg',
      fullSrc: 'assets/carousel-images/5.jpg',
      alt: 'Mowed loan and hedges in front garden'
    }
  ];

  constructor(public photoModalService: PhotoModalService) {
  }

  ngOnInit() {
    this.slideConfig = {
      slidesToShow: this.slidesToShow,
      slidesToScroll: 1,
      infinite: true,
      draggable: false,
    };
  }

  ngAfterViewInit() {
    this.slickTick();
    const cloneSlides = document.getElementsByClassName('slick-cloned');
    for (let i = 0; i < cloneSlides.length; i++) {
      const img = cloneSlides[i].children[0];
      img.addEventListener('click', this.imgOnClick.bind(this));
    }
  }

  // Setup timer to move to next slide
  slickTick() {
    setTimeout(() => {
      this.slickComponent.slickNext();
      this.slickTick();
    }, this.nextSlideDelay);
  }

  imgOnClick(clickEvent) {
    const src = clickEvent.srcElement.src;
    const thumbnailFileName = src.split('/')[src.split('/').length - 1];
    const modalSlide = this.slides.find(slide => slide.fullSrc.includes(thumbnailFileName));
    this.photoModalService.openModal(modalSlide);
  }

  // getSrcset(path) {
  //   return this.srcsetService.getSrcSet(path);
  // }
  //
  // getSizes() {
  //   return this.srcsetService.getSizes();
  // }
}

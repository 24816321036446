import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselComponent } from './carousel.component';
import { SlickModule } from 'ngx-slick';
import { PhotoModalService } from '../photo-modal/photo.modal.service';
import { SourceSetPipeModule } from '../source.set.pipe.module';

@NgModule({
  declarations: [
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    SlickModule.forRoot(),
    SourceSetPipeModule
  ],
  exports: [
    CarouselComponent
  ],
  providers: [
    PhotoModalService
  ]
})
export class CarouselModule {}

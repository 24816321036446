import { Component } from '@angular/core';
import { PhotoModalService } from './photo.modal.service';

@Component({
  selector: 'app-photo-modal',
  templateUrl: 'photo.modal.component.html',
  styleUrls: ['photo.modal.component.css'],
  preserveWhitespaces: true,
  animations: []
})
export class PhotoModalComponent {
  constructor(public photoModalService: PhotoModalService) {
  }

  closeModal() {
    this.photoModalService.showModal = false;
  }

  getShowModal() {
    return this.photoModalService.showModal && this.getSlide();
  }

  getSlide() {
    return this.photoModalService.slide;
  }
}

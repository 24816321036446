import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ContentComponent } from './content.component';
import { ScrollingService } from '../scrolling.service';
import { CarouselModule } from '../carousel/carousel.module';
import { FormsModule } from '@angular/forms';
import { SourceSetPipeModule } from '../source.set.pipe.module';

@NgModule({
  declarations: [
    ContentComponent
  ],
  imports: [
    BrowserModule,
    CarouselModule,
    FormsModule,
    SourceSetPipeModule
  ],
  exports: [
    ContentComponent
  ],
  providers: [
    ScrollingService
  ]
})
export class ContentModule {}

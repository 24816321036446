import { Component, ElementRef, HostListener } from '@angular/core';
import { ScrollingService } from './scrolling.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'browns-garden-services';
  widthBelow800: boolean;

  @HostListener('window:resize', [])
  onWindowResize(): void {
    this.widthBelow800 = window.innerWidth < 800;
  }

  constructor(public scrollingService: ScrollingService) {
    this.widthBelow800 = window.innerWidth < 800;
  }

  isScrolled() {
    return this.scrollingService.scrolled;
  }

  isWidthBelow800(): boolean {
    return this.widthBelow800;
  }
}

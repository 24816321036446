import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/environment';

@Pipe({name: 'sourceSet'})
export class SourceSetPipe implements PipeTransform {
  configs = environment.responsiveImageConfigurations;

  transform(path: string, type: string): string {
    const config = this.configs.find(candidate => candidate.type === type);
    let srcSet: string = '';
    Object.keys(config.dimensions).forEach(dimensionKey => {
      srcSet += path.replace('.jpg', '-' + dimensionKey + '.jpg ' + config.dimensions[dimensionKey][0] + 'w, ');
    });
    srcSet = srcSet.substring(0, srcSet.length - 2);
    // console.log(srcSet);
    return srcSet;
  }
}

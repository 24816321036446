import { NgModule } from '@angular/core';
import { SourceSetPipe } from './source.set.pipe';

@NgModule({
  declarations: [
    SourceSetPipe
  ],
  exports: [
    SourceSetPipe
  ]
})
export class SourceSetPipeModule {}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { ScrollingService } from '../scrolling.service';

@NgModule({
  declarations: [
    NavbarComponent
  ],
  imports: [
    BrowserModule
  ],
  exports: [
    NavbarComponent
  ],
  providers: [
    ScrollingService
  ]
})
export class NavbarModule {}

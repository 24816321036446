import { NgModule } from '@angular/core';
import { PhotoModalComponent } from './photo.modal.component';
import { ModalModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { PhotoModalService } from './photo.modal.service';

@NgModule({
  declarations: [
    PhotoModalComponent
  ],
  imports: [
    ModalModule.forRoot(),
    CommonModule
  ],
  providers: [
    PhotoModalService
  ],
  exports: [
    PhotoModalComponent
  ]
})
export class PhotoModalModule {
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent {
  scrollToById(elementId: string): void {
    document.getElementById(elementId).scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}

export const environment = {
  production: false,
  brown: '#5A4A42',
  green: '#82A150',
  invoker: {
    cognitoIdentityPoolId: 'ap-southeast-2:759f9a34-53e6-4904-aa97-5a8495cd1915',
    awsRegion: 'ap-southeast-2'
  },
  responsiveImageConfigurations: [
    {
      type: 'carousel',
      dimensions: {
        small: [160, 120],
        medium: [320, 240],
        large: [640, 480],
        xlarge: [1280, 960]
      }
    },
    {
      type: 'about-me',
      dimensions: {
        small: [233, 320],
        medium: [466, 640],
        large: [700, 960],
        xlarge: [933, 1280]
      }
    }
  ]
};

import { Component, HostListener } from '@angular/core';
import { ScrollingService } from '../scrolling.service';
import * as AWS from 'aws-sdk';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: [ './content.component.css' ]
})

export class ContentComponent {
  constructor(public scrollingService: ScrollingService) {
    this.widthBelow800 = window.innerWidth < 800;
    this.widthBelow1024 = window.innerWidth < 1024;
    this.widthBelow1280 = window.innerWidth < 1280;
  }

  widthBelow1024: boolean;
  widthBelow1280: boolean;
  widthBelow800: boolean;

  name: string = '';
  phone: string = '';
  suburb: string = '';
  message: string = '';

  emailSuccess: boolean = false;
  emailFail: boolean = false;
  emailProcessing: boolean = false;

  @HostListener('window:resize', [])
  onWindowResize(): void {
    this.widthBelow800 = window.innerWidth < 800;
    this.widthBelow1024 = window.innerWidth < 1024;
    this.widthBelow1280 = window.innerWidth < 1280;
  }

  onScroll() {
    const scrollingDiv = document.getElementById('scrollingDiv');
    this.scrollingService.scrolled = scrollingDiv.scrollTop > 0;
  }

  scrollToById(elementId: string): void {
    document.getElementById(elementId).scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  isWidthBelow1280(): boolean {
    return this.widthBelow1280;
  }

  isWidthBelow1024(): boolean {
    return this.widthBelow1024;
  }

  isWidthBelow800(): boolean {
    return this.widthBelow1024;
  }

  getAwsService() {
    const awsService: any = AWS;
    awsService.config = new AWS.Config({region: environment.invoker.awsRegion});
    awsService.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.invoker.cognitoIdentityPoolId
    });
    return awsService;
  }

  buildEmailText(): string {
    const email: string = 'Name: ' + this.name + '\nSuburb: ' + this.suburb + '\nPhone: ' + this.phone + '\n\n' + this.message;
    return email;
  }

  contactValidation(): boolean {
    return this.name !== '' && this.suburb !== '' && this.phone !== '' && this.message !== '';
  }

  sendEmail() {
    this.emailFail = false;
    this.emailSuccess = false;
    this.emailProcessing = true;

    const awsService = this.getAwsService();
    const ses = new awsService.SES({region: 'ap-southeast-2'});
    const params = {
      Destination: {
        ToAddresses: ['brownslawnlandscapeweb@gmail.com']
      },
      Message: {
        Body: {
          Text: {
            Data: this.buildEmailText()
          }
        },

        Subject: {
          Data: 'New message'
        }
      },
      Source: 'web@brownslawnandlandscape.com.au'
    };

    const promise = ses.sendEmail(params).promise();

    promise.then(
      (data) => {
        this.emailProcessing = false;
        this.emailSuccess = true;
        // this.emailFail = true;
      }).catch((err) => {
        this.emailProcessing = false;
        this.emailFail = true;
        console.log(err, err.stack);
      });
  }
}

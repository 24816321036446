import { Injectable } from '@angular/core';

@Injectable()
export class PhotoModalService {
  slide: any;
  showModal: boolean = false;

  constructor() {
  }

  openModal(slide: any) {
    this.slide = slide;
    this.showModal = true;
  }
}
